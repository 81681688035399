<template>
  <v-container id="DeviceCreateView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Create New Devices</div>
            <v-breadcrumbs
              class="px-0 py-2"
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            :disabled="!enable_create_btn"
            @click.prevent="createNewDeviceBtnClicked"
          >
            Save
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- Site Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Site Information</span
        >
      </v-col>
      <v-col cols="3">
        <v-select
          dense
          outlined
          clearable
          label="Site Name"
          item-text="siteName"
          item-value="_id"
          :items="site_list"
          v-model="selectedSite"
        >
          <template v-slot:item="{ item }">
            <div class="py-3">
              {{ item.siteName }}
            </div>
            <div
              v-if="item._id === last_site_item._id"
              v-intersect.quiet="onIntersect"
            ></div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
    </v-row>

    <!-- Device Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Device Information</span
        >
      </v-col>
    </v-row>
    <CreateDeviceCell
      v-for="(device, index) in create_devices"
      :key="index"
      :v-bind="device"
      :variables="device.variables"
      :advance="device.advance"
      :sequence="index + 1"
    />
    <!-- New Device Btn -->
    <v-row>
      <v-col cols="12" class="d-flex justify-center my-4">
        <v-btn
          small
          depressed
          outlined
          color="primary"
          @click="appendCreateDeviceObject"
        >
          <v-icon left> mdi-plus </v-icon>
          ADD MORE DEVICE
        </v-btn>
      </v-col>
    </v-row>

    <CreateDeviceDialog @closed="navigateToDeviceMainPage" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CreateDeviceCell from "@/components/cells/CreateDeviceCell";
import CreateDeviceDialog from "@/components/dialogs/CreateDeviceDialog";

export default {
  name: "DeviceCreateView",
  components: {
    CreateDeviceCell,
    CreateDeviceDialog,
  },
  data() {
    return {
      dialog: false,
      breadcrumbs: [
        {
          text: "Devices",
          exact: true,
          disabled: false,
          to: { name: "device" },
        },
        {
          text: "Create New Devices",
          exact: true,
          disabled: true,
          to: { name: "device-create" },
        },
      ],
      selectedSite: null,
    };
  },
  computed: {
    ...mapState({
      site_list: (state) => state.site.site_list,
      total_sites: (state) => state.site.total_sites,
      current_page: (state) => state.site.current_page,
      create_devices: (state) => state.device.create_devices,
    }),
    enable_create_btn: function () {
      return Boolean(this.selectedSite && this.create_devices);
    },
    last_site_item: function () {
      if (this.site_list && this.site_list.length > 0) {
        const tmp_arr = this.site_list.slice(-1);
        return tmp_arr[0];
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      requestGetSiteList: "requestGetSiteList",
      requestCreateNewDevice: "requestCreateNewDevice",
    }),
    ...mapMutations({
      resetSiteState: "RESET_SITE_STATE",
      setFilterPage: "SET_SITE_FILTER_PAGE",
      restateCreateDevice: "RESTATE_CREATE_DEVICE",
      appendCreateDeviceObject: "APPEND_CREATE_DEVICE_OBJECT",
      displayCreateDevicesDialog: "DISPLAY_CREATE_DEVICES_DIALOG",
    }),
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.site_list.length < this.total_sites) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetSiteList();
      }
    },
    paginationUpdated() {
      if (this.site_list.length < this.total_sites) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetSiteList();
      }
    },
    async createNewDeviceBtnClicked() {
      if (this.enable_create_btn) {
        this.displayCreateDevicesDialog();
        for (const create_device of this.create_devices) {
          if (
            Object.values(create_device).every((value) => {
              return value;
            })
          ) {
            const params = {
              siteId: this.selectedSite,
              deviceName: create_device.name,
              deviceType: create_device.type,
              deviceModel: {
                model: create_device.brand + "_" + create_device.model,
                variable: create_device.variables,
                advance: create_device.advance,
              },
            };
            await this.requestCreateNewDevice(params);
          }
        }
      }
    },
    navigateToDeviceMainPage() {
      this.$router.push({ name: "device" });
    },
  },
  async beforeMount() {
    await this.requestGetSiteList();
  },
  beforeRouteLeave(to, from, next) {
    this.resetSiteState();
    this.restateCreateDevice();
    next();
  },
};
</script>
