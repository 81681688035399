<template>
  <v-overlay
    id="CreateDeviceDialog"
    :opacity="0.3"
    :value="is_display_create_dialog"
  >
    <v-dialog v-model="is_display_create_dialog" persistent max-width="380">
      <v-card class="text-center mx-auto" flat rounded="lg">
        <v-card-title class="primary--text font-weight-bold justify-center">
          Create New Devices
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <v-progress-linear
            stream
            rounded
            height="10"
            buffer-value="0"
            :value="percent_create_device_progress"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="(device, i) in create_devices"
              :key="i"
              class="px-0 text-left"
            >
              <v-list-item-icon>
                <v-icon v-if="device.status === 'success'" color="light-green"
                  >mdi-check-circle</v-icon
                >
                <v-icon
                  v-else-if="device.status === 'fail'"
                  color="red darken-1"
                  >mdi-close-circle</v-icon
                >
                <v-icon v-else>mdi-dots-horizontal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ device.type }} | {{ device.name }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            rounded
            depressed
            color="primary"
            @click.prevent="dismissDialog"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "CreateDeviceDialog",
  computed: {
    ...mapState({
      create_devices: (state) => state.device.create_devices,
      is_display_create_dialog: (state) =>
        state.device.is_display_create_dialog,
    }),
    total_pending_create_devices: function () {
      return this.create_devices.filter((device) => device.status === "pending")
        .length;
    },
    percent_create_device_progress: function () {
      if (this.create_devices.length > 0) {
        return (
          (1 - this.total_pending_create_devices / this.create_devices.length) *
          100
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapMutations({
      hideCreateDevicesDialog: "HIDE_CREATE_DEVICES_DIALOG",
    }),
    dismissDialog() {
      this.hideCreateDevicesDialog();
      this.$emit("closed");
    },
  },
};
</script>

<style></style>
